import React from 'react';
import styled from 'styled-components';
import theme from 'styles/theme';
import Layout from 'components/Layout';
import Wrapper from 'components/Wrapper';
import HomeFeed from 'components/HomeFeed';
import Sidebar from 'components/Sidebar';
import TopAd from 'components/TopAd';
import Content from 'components/Content';
import text from 'config/text';
import SEO from 'utils/SEO';

const Page = styled.div`
	display: grid;
	width: 100%;
	${theme.media.large} {
		grid-template-areas: 'main side' 'feed side';
		grid-template-columns: minmax(0, 1fr) auto;
	}
	${theme.media.notlarge} {
		grid-template-areas: 'main' 'side' 'feed';
		grid-template-columns: 100%;
	}
	grid-gap: ${theme.size(4)};
`;
const Main = styled.div`
	grid-area: main;
	grid-gap: ${theme.size(2)};
	display: grid;
	& > * {
		background: ${theme.colors.background[0]};
		border-radius: ${theme.size(0.5)};
		padding: ${theme.size(6)};
	}
`;
const Side = styled.div`
	grid-area: side;
`;
const Feed = styled.div`
	grid-area: feed;
`;

export default props => {
	return (
		<Layout>
			<SEO title="Legal" />
			<Wrapper>
				<TopAd />
				<Page>
					<Main>
						<Content>
							<h1>Affiliate Disclosure</h1>
							<p>
								{text.logo.title} is a participant in the Amazon Services LLC Associates Program, an
								affiliate advertising program designed to provide a means for website owners to earn
								advertising fees by advertising and linking to amazon (.com, .co.uk, .ca etc) and any
								other website that may be affiliated with Amazon Service LLC Associates Program. As an
								Amazon Associate, we earn from qualifying purchases.
							</p>
							<p>
								There are links on this site that can be defined as "affiliate links", they allow
								companies to track where clicks to their websites are coming from. This means that we
								may receive a small commission (at no cost to you) if you subscribe or purchase
								something after clicking one of these links.
							</p>
							<p>
								Buying through those links does not cost you anything extra, and whether you buy through
								them or not is totally up to you. However, if you happen to really like the product we
								are promoting and decide to use our affiliate link, we would be very grateful for your
								support.
							</p>
							<p>
								If you have any questions about this, feel free to{' '}
								<a href="/contact">reach out via contact form</a>.
							</p>
						</Content>
						<Content>
							<h1>Terms and Conditions</h1>
							<h3>1. Terms</h3>
							<p>
								By accessing the website at {text.logo.title}, you are agreeing to be bound by these
								terms of service, all applicable laws and regulations, and agree that you are
								responsible for compliance with any applicable local laws. If you do not agree with any
								of these terms, you are prohibited from using or accessing this site. The materials
								contained in this website are protected by applicable copyright and trademark law.
							</p>
							<h3>2. Use License</h3>
							<p>
								Permission is granted to temporarily download one copy of the materials (information or
								software) on {text.logo.title}’s website for personal, non-commercial transitory viewing
								only. This is the grant of a license, not a transfer of title, and under this license
								you may not:
							</p>
							<p>- modify or copy the materials;</p>
							<p>
								- use the materials for any commercial purpose, or for any public display (commercial or
								non-commercial);
							</p>
							<p>
								- attempt to decompile or reverse engineer any software contained on {text.logo.title}’s
								website;
							</p>
							<p>remove any copyright or other proprietary notations from the materials; or</p>
							<p>
								- transfer the materials to another person or “mirror” the materials on any other
								server.
							</p>
							<p>
								This license shall automatically terminate if you violate any of these restrictions and
								may be terminated by {text.logo.title} at any time. Upon terminating your viewing of
								these materials or upon the termination of this license, you must destroy any downloaded
								materials in your possession whether in electronic or printed format.
							</p>
							<h3>3. Disclaimer</h3>
							<p>
								The materials on {text.logo.title}’s website are provided on an ‘as is’ basis.{' '}
								{text.logo.title} makes no warranties, expressed or implied, and hereby disclaims and
								negates all other warranties including, without limitation, implied warranties or
								conditions of merchantability, fitness for a particular purpose, or non-infringement of
								intellectual property or other violation of rights.
							</p>
							<p>
								Further, {text.logo.title} does not warrant or make any representations concerning the
								accuracy, likely results, or reliability of the use of the materials on its website or
								otherwise relating to such materials or on any sites linked to this site.
							</p>
							<h3>4. Limitations</h3>
							<p>
								In no event shall {text.logo.title} or its suppliers be liable for any damages
								(including, without limitation, damages for loss of data or profit, or due to business
								interruption) arising out of the use or inability to use the materials on{' '}
								{text.logo.title}’s website, even if {text.logo.title} or a {text.logo.title}
								authorized representative has been notified orally or in writing of the possibility of
								such damage. Because some jurisdictions do not allow limitations on implied warranties,
								or limitations of liability for consequential or incidental damages, these limitations
								may not apply to you.
							</p>
							<h3>5. Accuracy of materials</h3>
							<p>
								The materials appearing on {text.logo.title} website could include technical,
								typographical, or photographic errors. {text.logo.title} does not warrant that any of
								the materials on its website are accurate, complete or current. {text.logo.title} may
								make changes to the materials contained on its website at any time without notice.
								However {text.logo.title} does not make any commitment to update the materials.
							</p>
							<h3>6. Links</h3>
							<p>
								{text.logo.title} has not reviewed all of the sites linked to its website and is not
								responsible for the contents of any such linked site. The inclusion of any link does not
								imply endorsement by {text.logo.title} of the site. Use of any such linked website is at
								the user’s own risk.
							</p>
							<h3>7. Modifications</h3>
							<p>
								{text.logo.title} may revise these terms of service for its website at any time without
								notice. By using this website you are agreeing to be bound by the then current version
								of these terms of service.
							</p>
							<h3>8. Governing Law</h3>
							<p>
								These terms and conditions are governed by and construed in accordance with the laws of
								Netherlands and you irrevocably submit to the exclusive jurisdiction of the courts in
								that State or location.
							</p>
						</Content>
						<Content>
							<h1>Privacy Policy </h1>
							<h3>1. INTRODUCTION</h3>
							<h3>1.1. PURPOSE OF POLICY</h3>
							<p>
								{text.logo.title} (“us”, “we,” “Company”) is committed to respecting the data privacy
								rights of visitors and other users of {text.logo.title} (the “Site”).
							</p>
							<p>
								We created this Privacy Policy (this “Policy”) to give you confidence as you visit and
								use the Site, and to demonstrate our commitment to fair information practices. This
								Policy is only applicable to the Site, and not to any other websites that you may be
								able to access from the Site, each of which may have data collection and use practices
								and policies that differ materially from this Policy.
							</p>
							<h3>1.2. NOTICE CONCERNING CHILDREN</h3>
							<p>
								PLEASE NOTE: We are a general audience site, and do not direct any of our content
								specifically at children under 13 years of age pursuant to the Children’s Online Privacy
								Protection Act of 1998.
							</p>
							<h3>2. INFORMATION COLLECTION PRACTICES</h3>
							<h3>2.1. WHAT BASIC INFORMATION DOES THE COMPANY COLLECT?</h3>
							<p>
								In operating the Site, we collect personal information from you in a couple different
								situations. The first is if you should contact us via the “contact” page. In doing so,
								you will provide us with your name and email address. The second is if you leave a
								comment to a blog post during which you may be asked for a name and other information.
								You are not required to provide us with information via these two methods to use and
								enjoy the Site. We require this information in order to be able to communicate with you
								e.g. to reply to any queries you might have.
							</p>
							<h3>2.2. WHAT ADDITIONAL INFORMATION DOES COMPANY COLLECT?</h3>
							<p>
								(a) AUTOMATIC COLLECTION. Our servers automatically recognize visitors’ domain names and
								IP addresses (the number assigned to computers on the Internet). The Site may also
								gather anonymous “traffic data” that does not personally identify you. This information
								is gathered for  marketing purposes and to  improve the services we offer to you.
							</p>
							<p>
								(b) COOKIES. From time to time, we may use the standard “cookies” feature of major
								browser applications that allows us to store a small piece of data on your computer
								about your visit to our Site. Cookies help us learn which areas of our Site are useful
								and which areas need improvement through programs including, but not limited to, Google
								Analytics.
							</p>
							<p>
								We may also use cookies from third party social sites and programs including, but not
								limited to, Facebook, Google Plus and Twitter. You can choose to disable cookies through
								your browser or independent programs available online. However, if you choose to disable
								this function, your experience at our Site may be diminished as some features may not
								work as they were intended.
							</p>
							<p>
								(c) SPONSORS AND ADVERTISERS. We may use cookies to deliver content, including ads,
								relevant to your interests on our and third party sites based on how you interact with
								our advertisements or content. We have set out further information about the use of
								cookies by our Ad Network partners below. Your use of our site indicates your consent to
								such use of cookies.
							</p>
							<h3>2.3. GDPR Compliance</h3>
							<p>
								(a) Our data collection process is based upon Web Analytics, this data collection
								process may include anonymous, individual visitor click behavior. However, no personally
								identifiable information is obtained or shared with the Web Analytics company. Partners
								that may obtain this anonymous information are Google Analytics, Google AdSense,
								Taboola, Revcontent and Sulvo. Our Policies are in compliance with the updated General
								Data Protection Regulations applied by the European Union.
							</p>
							<p>
								(b) In depth information can be found here GDPR. To unsubscribe from data collection
								services please visit this Google
							</p>
							<h3>3. USE AND SHARING OF INFORMATION</h3>
							<h3>3.1. WHAT DOES COMPANY DO WITH COLLECTED INFORMATION?</h3>
							<p>
								(a) PERSONAL INFORMATION. We do not disclose the personally identifiable information to
								any third parties other than those that we use to facilitate the functioning of the site
								such as a hosting company and email program for mailings.
							</p>
							<p>
								(b) ANONYMOUS INFORMATION. We use anonymous information to analyze our Site traffic. In
								addition, we may use anonymous IP addresses to help diagnose problems with our server,
								to administer our site, or to display the content according to your preferences. Traffic
								and transaction information may also be shared with business partners and advertisers on
								an aggregate and anonymous basis.
							</p>
							<p>
								(c) USE OF COOKIES. Promotions or advertisements displayed on our site may contain
								cookies. We do not have access to or control over information collected by outside
								advertisers on our site.
							</p>
							<p>
								(d) DISCLOSURE OF PERSONAL INFORMATION. We may disclose any information we have for you
								if required to do so by law or in the good-faith belief that such action is necessary to
								(1) conform to the edicts of the law or comply with legal process served on us, (2)
								protect and defend our rights or property or the users of the Site, or (3) act under
								exigent circumstances to protect the safety of the public or users of the Site.
							</p>
							<p>
								(e) SALE OF INFORMATION. In order to accommodate changes in our business, we may sell or
								buy portions of the Site or our company, including the information collected through
								this Site. If Company or substantially all of its assets are acquired by a third party,
								user information will be one of the assets transferred to the acquirer.
							</p>
							<h3>4. SECURITY</h3>
							<p>
								The Site has security measures in place to prevent the loss, misuse, and alteration of
								the information that we obtain from you, but we make no assurances about our ability to
								prevent any such loss to you or to any third party arising out of any such loss, misuse,
								or alteration.
							</p>
							<h3>5. WEBSITE AREAS BEYOND COMPANY’S CONTROL</h3>
							<h3>5.1. THIRD PARTY WEBSITES</h3>
							<p>
								From time-to-time, the Site may contain links to other websites. If you choose to visit
								those websites, it is important to understand our privacy practices and terms of use do
								not extend to those sites. It is your responsibility to review the privacy policies at
								those websites to confirm that you understand and agree with their practices.
							</p>
							<h3>6. CONTACT INFORMATION AND POLICY UPDATES</h3>
							<p>6.1. CONTACTING US</p>
							<p>
								If you have any questions about this Policy or our practices related to this Site,
								please feel contact us using the “Contact” link on the menu located at the top of the
								site.
							</p>
							<h3>6.2. UPDATES AND CHANGES</h3>
							<p>
								We reserve the right, at any time, to add to, change, update, or modify this Policy to
								reflect changes in our Privacy Policy. We shall post any such changes on the Site in a
								conspicuous area. You may then choose whether you wish to accept said policy changes or
								discontinue using the Site. Any such change, update, or modification will be effective
								30 days after posting on the Site. It is your responsibility to review this Policy from
								time to time to ensure that you continue to agree with all of its terms.
							</p>
							<p>
								(a) If you have signed up for email communications from us, we will notify you of the
								privacy policy changes by email as well.
							</p>
						</Content>
					</Main>
					<Feed>
						<HomeFeed />
					</Feed>
					<Side>
						<Sidebar />
					</Side>
				</Page>
			</Wrapper>
		</Layout>
	);
};
